import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Img } from 'react-image';
import LazyLoad from 'react-lazyload';
import { Breakpoint } from '@styles/breakpoints';
import { buildLocalImageSrc } from '@utils/images';
import { PlcImgProps } from './PlcImg.types';

const PlcImg: FC<PlcImgProps> = ({
  plcSrc,
  alt,
  height,
  container,
  onLoad,
  loading = 'lazy',
  width,
  style,
  ...rest
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => (onLoad ? onLoad() : undefined), [imgRef.current]);

  const innerHtml = useMemo(() => {
    switch (plcSrc.type) {
      case 'local': {
        const { mobile, desktop } = buildLocalImageSrc(plcSrc.src);
        const desktopMedia = `(min-width: ${Breakpoint.m}px)`;
        const localInner = (
          <picture>
            {mobile && (
              <>
                <source media={desktopMedia} srcSet={desktop.original.src} />
              </>
            )}
            <source media={desktopMedia}  type="image/webp" />
            <img
              ref={imgRef}
              alt={alt}
              src={mobile ? mobile.original.src : desktop.original.src}
              style={height ? { ...style, height } : style}
              {...rest}
            />
          </picture>
        );
        return container ? container(localInner) : localInner;
      }
      case 'external': {
        return (
          // When react detect changing key its remount the component. Fix lazy load issue.
          <Img
            key={plcSrc.src}
            alt={alt}
            decode={false}
            height={height}
            loading={loading}
            onLoad={onLoad}
            src={plcSrc.src}
            width={width}
            {...rest}
          />
        );
      }
      default: {
        const switchTest: never = plcSrc;
        return switchTest;
      }
    }
  }, [plcSrc, alt, height, container, onLoad, loading, rest]);

  return (
    <div
      className={'plc-img'}
      style={{ marginLeft: width ? 'auto' : undefined, marginRight: width ? 'auto' : undefined, width }}
    >
      <LazyLoad height={height} offset={200} once={true} resize={true}>
        {innerHtml}
      </LazyLoad>
    </div>
  );
};

export { PlcImg };
