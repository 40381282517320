// @ts-nocheck
/* eslint-disable import/no-duplicates */
import creditAgricole from '../public/creditagricole.png';
// import creditAgricoleWebp from '../public/creditagricole.png?webp';
import allie1Mobile from '../public/img/allie-1-mobile.jpg';
import allie1MobileWebp from '../public/img/allie-1-mobile.jpg?webp';
import allie1 from '../public/img/allie-1.jpg';
import allie1Webp from '../public/img/allie-1.jpg?webp';
import allie2 from '../public/img/allie-2.jpg';
import allie2Webp from '../public/img/allie-2.jpg?webp';
import backgroundPlv from '../public/img/background-plv.jpg';
import backgroundPlvWebp from '../public/img/background-plv.jpg?webp';
import connexionMobile from '../public/img/connexion-768x167.jpg';
import connexionMobileWebp from '../public/img/connexion-768x167.jpg?webp';
import connexion from '../public/img/connexion-1440x300.jpg';
import connexionWebp from '../public/img/connexion-1440x300.jpg?webp';
import connexionBienvenueMobile from '../public/img/connexion-bienvenue-586x446.jpg';
import connexionBienvenueMobileWebp from '../public/img/connexion-bienvenue-586x446.jpg?webp';
import connexionBienvenueProMobile from '../public/img/connexion-bienvenue-pro-586x446.jpg';
import connexionBienvenueProMobileWebp from '../public/img/connexion-bienvenue-pro-586x446.jpg?webp';
import connexionBienvenuePro from '../public/img/connexion-bienvenue-pro.jpg';
import connexionBienvenueProWebp from '../public/img/connexion-bienvenue-pro.jpg?webp';
import connexionBienvenue from '../public/img/connexion-bienvenue.jpg';
import connexionBienvenueWebp from '../public/img/connexion-bienvenue.jpg?webp';
import JeuSalonAutomneMobile from '../public/img/game/Jeu-Pleinchamp-ENLIGNE.jpg';
import JeuSalonAutomne from '../public/img/game/Jeu-Pleinchamp-ENLIGNE.jpg';
import JeuSalonAutomneMobileWebp from '../public/img/game/Jeu-Pleinchamp-ENLIGNE.jpg?webp';
import JeuSalonAutomneWebp from '../public/img/game/Jeu-Pleinchamp-ENLIGNE.jpg?webp';
import villageByCaBlack from '../public/img/innovation/village_by_ca_black-200x160.png';
import villageByCaBlackMobile from '../public/img/innovation/village_by_ca_black-200x160.png';
import villageByCaBlackWebp from '../public/img/innovation/village_by_ca_black-200x160.png?webp';
import villageByCaBlackMobileWebp from '../public/img/innovation/village_by_ca_black-200x160.png?webp';
import villageByCaWhiteMobile from '../public/img/innovation/village_by_ca_white-200x160.png';
import villageByCaWhiteMobileWebp from '../public/img/innovation/village_by_ca_white-200x160.png?webp';
import villageByCaWhite from '../public/img/innovation/village_by_ca_white.png';
import villageByCaWhiteWebp from '../public/img/innovation/village_by_ca_white.png?webp';
import inscriptionMobile from '../public/img/inscription-768x167.jpg';
import inscriptionMobileWebp from '../public/img/inscription-768x167.jpg?webp';
import inscription from '../public/img/inscription-1440x300.jpg';
import inscriptionWebp from '../public/img/inscription-1440x300.jpg?webp';
import journalMeteoMobile from '../public/img/journal/journal-meteo-738x555.jpg';
import journalMeteoMobileWebp from '../public/img/journal/journal-meteo-738x555.jpg?webp';
import journalMeteo from '../public/img/journal/journal-meteo.jpg';
import journalMeteoWebp from '../public/img/journal/journal-meteo.jpg?webp';
import journalProMobile from '../public/img/journal/journal-pro-696x575.jpg';
import journalProMobileWebp from '../public/img/journal/journal-pro-696x575.jpg?webp';
import journalPro from '../public/img/journal/journal-pro.jpg';
import journalProWebp from '../public/img/journal/journal-pro.jpg?webp';
import journalStockMobile from '../public/img/journal/journal-stocks-738x555.jpg';
import journalStockMobileWebp from '../public/img/journal/journal-stocks-738x555.jpg?webp';
import journalStock from '../public/img/journal/journal-stocks.jpg';
import journalStockWebp from '../public/img/journal/journal-stocks.jpg?webp';
import notificationMobile from '../public/img/notifications-768x167.jpg';
import notificationMobileWebp from '../public/img/notifications-768x167.jpg?webp';
import notification from '../public/img/notifications-1440x300.jpg';
import notificationWebp from '../public/img/notifications-1440x300.jpg?webp';
import preferencesMobile from '../public/img/preferences-768x167.jpg';
import preferencesMobileWebp from '../public/img/preferences-768x167.jpg?webp';
import preferences from '../public/img/preferences-1440x300.jpg';
import preferencesWebp from '../public/img/preferences-1440x300.jpg?webp';
import profilMobile from '../public/img/profil-768x167.jpg';
import profilMobileWebp from '../public/img/profil-768x167.jpg?webp';
import profil from '../public/img/profil-1440x300.jpg';
import profilWebp from '../public/img/profil-1440x300.jpg?webp';
import maskableIconMobile from '../public/maskable_icon-192x192.png?';
import maskableIconMobileWebp from '../public/maskable_icon-192x192.png?webp';
import maskableIcon from '../public/maskable_icon.png';
import maskableIconWebp from '../public/maskable_icon.png?webp';

export {
  JeuSalonAutomne,
  JeuSalonAutomneMobile,
  // JeuSalonAutomneMobileWebp,
  // JeuSalonAutomneWebp,
  allie1Mobile,
  // allie1MobileWebp,
  allie1,
  // allie1Webp,
  allie2,
  // allie2Webp,
  backgroundPlv,
  // backgroundPlvWebp,
  connexion,
  connexionBienvenue,
  connexionBienvenueMobile,
  // connexionBienvenueMobileWebp,
  connexionBienvenuePro,
  connexionBienvenueProMobile,
  // connexionBienvenueProMobileWebp,
  // connexionBienvenueProWebp,
  // connexionBienvenueWebp,
  connexionMobile,
  // connexionMobileWebp,
  // connexionWebp,
  creditAgricole,
  // creditAgricoleWebp,
  inscription,
  inscriptionMobile,
  // inscriptionMobileWebp,
  // inscriptionWebp,
  journalMeteo,
  journalMeteoMobile,
  // journalMeteoMobileWebp,
  // journalMeteoWebp,
  journalPro,
  journalProMobile,
  // journalProMobileWebp,
  // journalProWebp,
  journalStock,
  journalStockMobile,
  // journalStockMobileWebp,
  // journalStockWebp,
  maskableIcon,
  maskableIconMobile,
  // maskableIconMobileWebp,
  // maskableIconWebp,
  notification,
  notificationMobile,
  // notificationMobileWebp,
  // notificationWebp,
  preferences,
  preferencesMobile,
  // preferencesMobileWebp,
  // preferencesWebp,
  profil,
  profilMobile,
  // profilMobileWebp,
  // profilWebp,
  villageByCaBlack,
  villageByCaBlackMobile,
  // villageByCaBlackMobileWebp,
  // villageByCaBlackWebp,
  villageByCaWhite,
  villageByCaWhiteMobile,
  // villageByCaWhiteMobileWebp,
  // villageByCaWhiteWebp,
};
